<template>
  <div>
    <template>
      <v-app>
        <div v-for="index in printPage" :key="index" class="html2pdf__page-break">
          <div style="height: 1050px">
          <div
            class="mx-auto"
            style="min-height: 200px"
          >
            <v-card-title class="py-1" v-if="!Object.keys(pdfTemplateValueList).length">
              <v-row dense no-gutters>
                <v-col cols="12" class="text-center primary--text" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center primary--text" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="font-weight-regular mt-n2">Test Software By BINA</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('orderPdf.companyAddress') }}</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('orderPdf.tel') }}: 972-3-7361150 {{ $t('orderPdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12">
                  <h5 class="font-weight-regular mt-n2">{{ $t('orderPdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h5 class="text-decoration-underline mt-n2 primary--text">{{ $t('orderPdf.customerOrderNo') }} {{model.id}}</h5>
                </v-col>
                <v-col cols="12">
                  <table
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <div class="text-body-1 text-center">{{model.title}}</div>
                        </td>
                      </tr>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <v-row dense>
                            <v-col cols="6">
                              <p class="text-body-1 font-weight-bold mb-0">{{ $t('orderPdf.To') }}</p>
                              <p class="text-body-2 font-weight-bold primary--text mb-0">{{model.customer ? model.customer_name ? model.customer_name : model.customer.name_1 : ''}}</p>
                              <p class="text-body-2 mb-0">{{model.address}}</p>
                              <p class="text-body-2 mb-0">{{ $t('orderPdf.tel') }} {{model.phone_1}}</p>
                              <p class="text-body-2 mb-0 text-decoration-underline font-weight-bold">{{ $t('orderPdf.att') }}: <span class="primary--text">{{model.contact ? model.contact.name : ''}}</span></p>
                            </v-col>
                            <v-col cols="6" class="text-end">
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.date') }}:</span> <span class="primary--text">{{model.order_date}}</span></p>
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.validTtill') }}:</span> <span class="primary--text">{{model.delivery_date}}</span></p>
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.printedAt') }}:</span> <span class="primary--text">{{getNowDate(model.date_time_printed)}}</span></p>
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.printingHr') }}:</span> <span class="primary--text">{{getNowTime(model.date_time_printed)}}</span></p>
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.ourNumber') }}:</span> <span class="primary--text">{{model.customer_id}}</span></p>
                              <p v-if="model.customer_order_no" class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('orderPdf.yourOrder') }}:</span> <span class="primary--text">{{model.customer_order_no}}</span></p>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p class="text-body-2 my-2" style="word-break:break-word;">
                            {{company.beginning_order_text}}
                          </p>
                          <v-divider />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="py-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
              <v-row dense no-gutters>
                <v-col cols="12" class="text-center" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="mt-n2">Test Software By BINA</h5>
                  <h5 class="mt-n2">{{ $t('orderPdf.companyAddress') }}</h5>
                  <h5 class="mt-n2">{{ $t('orderPdf.tel') }}: 972-3-7361150 {{ $t('orderPdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12">
                  <h5 class="mt-n2">{{ $t('orderPdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h5 class="mt-n2">{{ $t('orderPdf.customerOrderNo') }} {{model.id}}</h5>
                </v-col>
                <v-col cols="12">
                  <table
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <div class="text-center">{{model.title}}</div>
                        </td>
                      </tr>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <v-row dense>
                            <v-col cols="6">
                              <p class="mb-0">{{ $t('orderPdf.To') }}</p>
                              <p class="mb-0">{{model.customer ? model.customer_name ? model.customer_name : model.customer.name_1 : ''}}</p>
                              <p class="mb-0">{{model.address}}</p>
                              <p class="mb-0">{{ $t('orderPdf.tel') }} {{model.phone_1}}</p>
                              <p class="mb-0">{{ $t('orderPdf.att') }}: <span>{{model.contact ? model.contact.name : ''}}</span></p>
                            </v-col>
                            <v-col cols="6" class="text-end">
                              <p class="mb-0"><span>{{ $t('orderPdf.date') }}:</span> <span>{{model.order_date}}</span></p>
                              <p class="mb-0"><span>{{ $t('orderPdf.validTtill') }}:</span> <span>{{model.delivery_date}}</span></p>
                              <p class="mb-0"><span>{{ $t('orderPdf.printedAt') }}:</span> <span>{{getNowDate(model.date_time_printed)}}</span></p>
                              <p class="mb-0"><span>{{ $t('orderPdf.printingHr') }}:</span> <span>{{getNowTime(model.date_time_printed)}}</span></p>
                              <p class="mb-0"><span>{{ $t('orderPdf.ourNumber') }}:</span> <span>{{model.customer_id}}</span></p>
                              <p v-if="model.customer_order_no" class="mb-0"><span>{{ $t('orderPdf.yourOrder') }}:</span> <span class="primary--text">{{model.customer_order_no}}</span></p>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p class="my-2" style="word-break:break-word;">
                            {{company.beginning_order_text}}
                          </p>
                          <v-divider />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <div
            class="mx-auto"
            flat
            ref="itemContent"
          >
            <v-card-title class="py-1">
              <v-row dense>
                <v-col cols="12" class="text-body-2 py-0 text-end">
                  {{ $t('orderPdf.page') }} {{index}} {{ $t('orderPdf.of') }} {{printPage}}
                </v-col>
                <v-col cols="12" class="product">
                  <table
                    width="100%"
                    class="my-0 text-body-2"
                    v-if="model.orderItems"
                  >
                    <thead>
                      <tr>
                        <th class="text-start ps-1" :style="{width: '40px'}">
                          {{ $t('orderPdf.no') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '150px'}">
                          {{ $t('orderPdf.product') }}
                        </th>
                        <th v-if="totalLineItemDescription !== 'null'" class="text-start ps-1" :style="{width: '430px'}">
                          {{ $t('orderPdf.itemDescription') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '120px'}">
                          {{ $t('orderPdf.quantity') }}
                        </th>
                        <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '120px'}">
                          {{ $t('orderPdf.unitPrice') }}
                        </th>
                        <th v-if="accessRight.includes('financial') && (totalLineDiscount || (model.total == 0 && !totalLineDiscount))" class="text-start ps-1" :style="{width: '150px'}">
                          {{ $t('orderPdf.lineDiscount') }}
                        </th>
                        <th v-if="accessRight.includes('financial')" class="text-center" :style="{width: '150px'}">
                          {{ !modelData.including_vat ? $t('orderPdf.total') : $t("company.field.totalIncludingVAT") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="model.total == 0">
                        <td colspan="7" class="text-center">{{$t('common.noDataFound')}}</td>
                      </tr>
                      <template v-else v-for="n in lastIndex[index-1] ? getNumbers(index == 1 ? 1 : lastIndex[index-2] ? lastIndex[index-2] : lastIndex[index-1],lastIndex[index-1] ? lastIndex[index-1] : orderItemsObject.length + 1) :  lastIndex[index-2] ? getNumbers(lastIndex[index-2], orderItemsObject.length + 1): orderItemsObject.length">
                        <template v-if="orderItemsObject[n-1].source_document_number">
                          <tr :key="'new_line_' + orderItemsObject[n-1].same_doc_id + '_source_doc' + n"  :style='"background-color: #CFD8DC"'>
                            <td colspan="1"></td>
                            <td><span>-</span></td>
                            <td :colspan="orderItemsObject[n-1].line_discount ? 5 : 4">
                              <span>
                                <b>{{$t('order.field.docNo')}}:</b> {{orderItemsObject[n-1].source_document_number}}<br />
                              </span>
                              <span>
                                <b>{{$t('order.field.docDate')}}:</b> {{orderItemsObject[n-1].source_document_date.split('T')[0]}}<br />
                              </span>
                              <span v-if="orderItemsObject[n-1].source_document_title">
                                <b>{{$t('order.field.docTitle')}}:</b> {{orderItemsObject[n-1].source_document_title}}<br />
                              </span>
                              <span v-if="orderItemsObject[n-1].source_document_customer_order_no">
                                <b>{{$t('order.field.customerOrderNo')}}:</b> {{orderItemsObject[n-1].source_document_customer_order_no}}<br />
                              </span>
                            </td>
                          </tr>
                        </template>
                        <tr :key="n">
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{n}}
                          </td>
                          <td class="ps-1">
                            {{orderItemsObject[n-1] ? orderItemsObject[n-1].item_code : ''}}
                          </td>
                          <td class="ps-1" v-if="totalLineItemDescription !== 'null'" style="word-break:break-word;">
                            {{orderItemsObject[n-1] ? orderItemsObject[n-1].item_description : ''}}
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{orderItemsObject[n-1] && orderItemsObject[n-1].quantity ? orderItemsObject[n-1].quantity : ''}}
                          </td>
                          <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{orderItemsObject[n-1] && orderItemsObject[n-1].unit_price ? numberWithCommas(orderItemsObject[n-1].unit_price) : ''}}
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'" v-if="accessRight.includes('financial') && (totalLineDiscount || (model.total == 0 && !totalLineDiscount))">
                            {{orderItemsObject[n-1] && orderItemsObject[n-1].line_discount ? numberWithCommas(orderItemsObject[n-1].line_discount) : ''}}
                          </td>
                          <td v-if="accessRight.includes('financial')" class="pe-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{orderItemsObject[n-1] && orderItemsObject[n-1].total ? numberWithCommas(orderItemsObject[n-1].total) : ''}}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title v-if="index == printPage" class="py-0">
              <v-row dense no-gutters class="text-end">
                <v-col cols="9" class="text-body-2 text-start" style="word-break:break-word;">
                  <p v-if="model.remarks">{{model.remarks}}</p>
                  <p>{{company.end_order_text}}</p>
                </v-col>
                <v-col cols="3" v-if="accessRight.includes('financial')" class="text-body-2">
                  <v-row dense no-gutters>
                    <v-col cols="6" class="font-weight-bold">
                      {{$t('orderPdf.total')}}
                    </v-col>
                    <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.total)}}
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col cols="6">
                      {{$t('orderPdf.field.discount')}}
                    </v-col>
                    <v-col cols="6" class="ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.discount)}}
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters v-if="model.discount">
                    <v-col cols="6">
                      {{$t('orderPdf.field.afterDiscount')}}
                    </v-col>
                    <v-col cols="6" class="ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.after_discount)}}
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col cols="6">
                      {{$t('orderPdf.field.vat')}}
                    </v-col>
                    <v-col cols="6" class="ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.vat)}}
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col cols="6" class="font-weight-bold">
                      {{$t('orderPdf.field.grandTotal')}}
                    </v-col>
                    <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.grand_total)}}
                    </v-col>
                  </v-row>
                  <v-row v-if="index === printPage" class="text-end">
                    <v-col cols="12" class="text-body-1" v-if="!Object.keys(pdfTemplateValueList).length">
                      {{$t('orderPdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span class="text-body-2">{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                    <v-col cols="12" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                      {{$t('orderPdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          </div>
          <div class="pb-0 px-4 pt-1" color="white">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-img width="100%" eager :src="bottom" v-if="index == 1 && bottom"/>
                <v-img width="100%" eager :lazy-src="bottom" v-if="index !== 1 && bottom"/>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-app>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {formatDateDDMMYYYY} from '@/utils';

export default {
  name: "PrintOrder",
  props:['modelData', 'pdfTemplateValueList'],
  data() {
    return{
      company: '',
      printPage: 1,
      rowCount: 0,
      lastIndex: [],
      topLogoUrl: null,
      bottomLogoUrl: null,
      totalLineDiscount: 0,
      totalLineItemDescription: null,
      orderItemsObject: null
    }
  },
  async mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
    this.company = await this.$store.dispatch('profile/GetCompanyById');
    if(this.company.topLogo) {
      this.getBase64Image(this.company.topLogo.file_path, 'topLogo');
    }
    if(this.company.bottomLogo) {
      this.getBase64Image(this.company.bottomLogo.file_path, 'bottomLogo');
    }
  },
  watch: {
    modelData: {
      handler(){
        this.totalRow;
      },
      deep: true
    }
  },
  computed: {
    model() {
      return this.modelData;
    },
    bottom() {
      return this.bottomLogoUrl;
    },
    top() {
      return this.topLogoUrl;
    },
    ...mapGetters({
      locale: 'locale',
      accessRight: 'accessRight',
      user: 'user',
      profileById: 'profile/profileById',
    }),
    totalRow() {
      let rowCount = 0;
      // let lastIndexNo = 0;
      if(this.model.title) {
        rowCount = this.model.title.match(/.{1,97}/g).length-1 >= 1 ? Number(rowCount) + (Number(this.model.title.match(/.{1,97}/g).length-1)) : Number(rowCount) + 1;
      }
      if(this.model.customer?.name_1) {
        rowCount = this.model.customer?.name_1.match(/.{1,52}/g).length-1 >= 1 ? Number(rowCount) + (Number(this.model.customer?.name_1.match(/.{1,52}/g).length-1)) : Number(rowCount) + 1;
      }
      if(this.model.contact?.name) {
        rowCount = this.model.contact?.name.match(/.{1,52}/g).length-1 >= 1 ? Number(rowCount) + (Number(this.model.contact?.name.match(/.{1,52}/g).length-1)) : Number(rowCount) + 1;
      }
      if(this.model.address) {
        rowCount = this.model.address.match(/.{1,52}/g).length-1 >= 1 ? Number(rowCount) + (Number(this.model.address.match(/.{1,52}/g).length-1)) : Number(rowCount) + 1;
      }
      if(this.company.beginning_proposal_text) {
        rowCount = this.company.beginning_proposal_text.match(/.{1,119}/g).length-1 >= 1 ? Number(rowCount) + (Number(this.company.beginning_proposal_text.match(/.{1,119}/g).length-1)) : Number(rowCount) + 1;
      }
      let beforeRowCount = rowCount;
      if(this.model.orderItems) {
        // let tempCount = 0;
        /* eslint-disable */
        this.totalLineDiscount = 0;
        this.totalLineItemDescription = null;
        this.orderItemsObject = this.model.orderItems.filter((item) => {
          // if(item.total > 0) {
            this.totalLineDiscount += item.line_discount;
            this.totalLineItemDescription += item.item_description;
            return item;
          // }
        })
        /* eslint-enable */
        this.orderItemsObject.forEach((element,index) => {
          if(element.item_description || element.item_code) {
            let itemDescriptionCount = 0;
            if(element.item_description) {
              itemDescriptionCount = element.item_description.match(/.{1,29}/g).length-1 >= 1 ? Number(itemDescriptionCount) + (Number(element.item_description.match(/.{1,29}/g).length-1)) : Number(itemDescriptionCount) + 1;
            }

            let itemCodeCount = 0;
            if(element.item_code) {
              itemCodeCount = element.item_code.match(/.{1,13}/g).length-1 >= 1 ? Number(itemCodeCount) + (Number(element.item_code.match(/.{1,13}/g).length-1)) : Number(itemCodeCount) + 1;
            }

            rowCount = Number(rowCount) + Number(Math.max(itemDescriptionCount, itemCodeCount));

            if(index == this.orderItemsObject.length-1) {
              rowCount = Number(rowCount) + 5;
            }

            let tempCount = 0;
            if(tempCount < rowCount && rowCount >= 28) {
              tempCount = rowCount;
              this.lastIndex.push(this.printPage == 1 ? index+1 : index+1);
              this.printPage += 1;

              rowCount = Number(Math.max(itemDescriptionCount, itemCodeCount)) + beforeRowCount;
            }
          }
        });
      }
      return rowCount;
    }
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    getNowTime(newDate) {
      if(!newDate) return null;
      const date = new Date()
      return ('0'+(date.getHours())).slice(-2)+':'+('0'+(date.getMinutes())).slice(-2);
    },
    getNowDate(newDate) {
      if(!newDate) return null;
      const date = new Date()
      const dateData = date.getFullYear()+'-'+('0'+(date.getMonth()+1)).slice(-2)+'-'+date.getDate();
      return this.profileById.date_format ? this.formatDateDDMMYYYY(dateData) : dateData;
    },
    getBase64Image(imgUrl, type) {
      let img = new Image();
      let self = this;
      // onload fires when the image is fully loadded, and has width and height
      img.onload = function(){
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png")
        type === 'bottomLogo' ? self.bottomLogoUrl = dataURL : self.topLogoUrl = dataURL; // the base64 string
      };
      // set attributes and src 
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl + '?' + new Date().toLocaleString('sv-SE');
    },
    getNumbers:function(start,stop){
      return new Array(stop-start).fill(start).map((n,i)=>n+i);
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : 0;
    },
  }
}
</script>
<style scoped>
.product table {
  border-collapse: collapse;
}
.product table, .product table td {
  border: 0px solid black;
}
.product table {
  border-width: 1px 1px 1px 1px;
}
.product table td {
  border-width: 1px 0 0 1px;
}
.product th {
  border: 0px solid black;
  border-width: 0 0 0 1px;
}
#footer .v-image {
  width: 110px;
  right: 0;
  position: absolute;
  left: 0;
}
</style>